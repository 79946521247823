import { getTags } from '../selectors';

export default function filterAppsByCategory(apps, categoryKey) {
  return apps?.filter((app) => {
    let tags = getTags(app);

    if (tags) {
      tags = tags.map((t) => t.toLowerCase());
    }

    return (
      categoryKey === 'All' ||
      (tags && tags.indexOf(categoryKey.toLowerCase()) !== -1)
    );
  });
}
