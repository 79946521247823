import React from 'react';
import styled from 'styled-components';

export const HeroPicture = styled.picture``;
export const HeroImg = styled.img``;

interface Props {
  staticFile: string;
  alt: string;
}

const HeroImage: React.FC<Props> = ({ staticFile, alt }) => {
  const [name] = staticFile.split('.');
  return (
    <HeroPicture>
      <source srcSet={`/static/${name}.webp`} type="image/webp" />
      <source srcSet={`/static/${staticFile}`} />
      <HeroImg src={`/static/${staticFile}`} alt={alt} />
    </HeroPicture>
  );
};

export default HeroImage;
